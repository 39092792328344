import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import Sidebar from './Sidebar';
import HomePage from './homepage/HomePage';
import PaidUsers from './paid-users/PaidUsers';
import SuiteRunPage from './suite-run/SuiteRunPage';
import AuthPage from './AuthPage';
import {Amplify} from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import {useAuthenticator} from '@aws-amplify/ui-react';
import awsconfig from './aws-exports';
import SpecRunPage from './spec-run/SpecRunPage';
import SpecRunPageBackend from "./spec-run-backend/SpecRunPageBackend";
import YogaOtpCodePage from "./gmail/YogaOtpCode";
import MuscleboosterOtpCodePage from "./gmail/MuscleboosterOtpCode";

// Define a flag to control authentication
const isAuthEnabled = true; // Change this to true when you want to enable authentication

if (isAuthEnabled) {
    Amplify.configure(awsconfig);
}

function App() {
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const {route} = useAuthenticator((context) => [context.route]);
    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };
    if (route === 'authenticated' || !isAuthEnabled) {
        return (
            <Router>
                <div className="app-container">
                    {!isSidebarVisible && (
                        <div className="burger-menu" onClick={toggleSidebar}>
                            ☰
                        </div>
                    )}
                    {isSidebarVisible && <Sidebar onClose={toggleSidebar}/>}
                    <div
                        className={`content ${isSidebarVisible ? 'content-shifted' : ''}`}
                    >
                        <Routes>
                            <Route path="/" element={<HomePage/>}/> {/* Default route */}
                            <Route path="/paid-user" element={<PaidUsers/>}/>
                            <Route path="/suites-run" element={<SuiteRunPage/>}/>
                            <Route path="/spec-run" element={<SpecRunPage/>}/>
                            <Route path="/spec-run-backend" element={<SpecRunPageBackend/>}/>
                            <Route path="/yoga-otp" element={<YogaOtpCodePage/>}/>
                            <Route path="/mbw-otp" element={<MuscleboosterOtpCodePage/>}/>
                            <Route path="/auth" element={<AuthPage/>}/>
                        </Routes>
                    </div>
                </div>
            </Router>
        );
    } else {
        return <AuthPage/>;
    }
}

export default App;
