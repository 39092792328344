import config from "../config";

const GMAIL_API_BASE_URL = "https://www.googleapis.com/gmail/v1";
const tokenUri = "https://oauth2.googleapis.com/token";

// Maintain access tokens per prefix
const accessTokens = {};

const getCredentials = (prefix) => {
    const credentials = {
        accessToken: accessTokens[prefix] || null,
        refreshToken: config[`${prefix}_GMAIL_REFRESH_TOKEN`],
        clientId: config[`${prefix}_GMAIL_CLIENT_ID`],
        clientSecret: config[`${prefix}_GMAIL_CLIENT_SECRET`],
    };

    for (const [key, value] of Object.entries(credentials)) {
        if (!value) {
            console.error(`Missing ${key} for prefix: ${prefix}`);
        }
    }

    return credentials;
};

const refreshAccessToken = async (prefix) => {
    const {refreshToken, clientId, clientSecret} = getCredentials(prefix);

    try {
        const response = await fetch(tokenUri, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams({
                client_id: clientId,
                client_secret: clientSecret,
                refresh_token: refreshToken,
                grant_type: "refresh_token",
            }),
        });

        const data = await response.json();
        if (data.access_token) {
            // Save the refreshed token for the prefix
            accessTokens[prefix] = data.access_token;
            console.log(`Access token refreshed for prefix: ${prefix}`);
        } else {
            throw new Error(`Failed to refresh access token: ${JSON.stringify(data)}`);
        }
    } catch (error) {
        console.error("Error refreshing token:", error);
        throw error;
    }
};

export const fetchEmails = async (prefix, emailAddress = '', subjectFilter = '', maxResults = 10) => {
    const {accessToken} = getCredentials(prefix);

    try {
        let query = '';
        if (emailAddress) query += `to:${emailAddress} `;
        if (subjectFilter) query += `subject:${subjectFilter}`;

        const response = await fetch(
            `${GMAIL_API_BASE_URL}/users/me/messages?maxResults=${maxResults}&q=${encodeURIComponent(query)}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        if (response.status === 401) {
            console.warn("Access token expired. Refreshing token...");
            await refreshAccessToken(prefix);
            return fetchEmails(prefix, emailAddress, subjectFilter, maxResults);
        }

        const data = await response.json();
        return data.messages || [];
    } catch (error) {
        console.error("Error fetching emails:", error);
        return [];
    }
};

export const fetchEmailDetails = async (prefix, messageId) => {
    const {accessToken} = getCredentials(prefix);

    try {
        const response = await fetch(
            `${GMAIL_API_BASE_URL}/users/me/messages/${messageId}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        if (response.status === 401) {
            console.warn("Access token expired. Refreshing token...");
            await refreshAccessToken(prefix);
            return fetchEmailDetails(prefix, messageId);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching email details:", error);
        return null;
    }
};
