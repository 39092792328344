import React, {useState} from 'react';
import {fetchEmailDetails, fetchEmails} from '../backend/GmailService';

function MuscleboosterOtpCodePage() {
    const [emails, setEmails] = useState([]);
    const [selectedEmailId, setSelectedEmailId] = useState(null);
    const [filterEmail, setFilterEmail] = useState('');
    const [selectedEmailText, setSelectedEmailText] = useState('');
    const [selectedEmailDate, setSelectedEmailDate] = useState('');
    const [errorText, setErrorText] = useState('');

    const handleFetchEmails = async () => {
        setErrorText('');
        setEmails([]);
        setSelectedEmailId(null);

        if (!filterEmail.trim()) {
            setErrorText('Recipient email is required');
            return;
        }

        try {
            const passwordTranslations = ['', 'пароль', 'Password', 'temporal', 'Senha', 'temporaire', 'Passwort'];
            const subjectFilter = `(${passwordTranslations.map((word) => `subject:${word}`).join(' OR ')})`;

            const messages = await fetchEmails('MB', filterEmail, subjectFilter, 10);
            if (messages.length === 0) {
                setErrorText('No emails found matching the filters.');
            } else {
                setEmails(messages);
            }
        } catch (error) {
            console.error('Error fetching emails:', error);
            setErrorText('Failed to fetch emails. Please try again.');
        }
    };

    const handleEmailClick = async (emailId) => {
        if (selectedEmailId === emailId) {
            setSelectedEmailId(null);
            setSelectedEmailText('');
            setSelectedEmailDate('');
            return;
        }

        try {
            const emailDetails = await fetchEmailDetails('MB', emailId);
            const snippet = emailDetails.snippet || 'No content available';

            // Extract the Date header from emailDetails
            const dateHeader = emailDetails.payload.headers.find(
                (header) => header.name.toLowerCase() === 'date'
            );
            const date = dateHeader ? new Date(dateHeader.value).toLocaleString() : 'Unknown date';

            setSelectedEmailId(emailId);
            setSelectedEmailText(snippet);
            setSelectedEmailDate(date);
        } catch (error) {
            console.error('Error fetching email details:', error);
        }
    };

    const handleClearResults = () => {
        setEmails([]);
        setSelectedEmailId(null);
        setSelectedEmailText('');
        setSelectedEmailDate('');
        setErrorText('');
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleFetchEmails();
    };

    return (
        <div className="container">
            <h1 className="title">Get OTP code from Gmail For MuscleBooster</h1>
            <form onSubmit={handleFormSubmit}>
                <label>
                    Recipient Email:
                    <input
                        type="email"
                        value={filterEmail}
                        onChange={(e) => setFilterEmail(e.target.value)}
                        placeholder="Enter recipient email"
                        className="input-field"
                        required
                    />
                </label>
                <div className="buttons">
                    <button
                        type="submit"
                        className="button"
                        disabled={!filterEmail.trim()}
                    >
                        Fetch Emails
                    </button>
                    <button
                        type="button"
                        onClick={handleClearResults}
                        className="button"
                    >
                        Clear Results
                    </button>
                </div>
            </form>
            {errorText && <p className="error-text">{errorText}</p>}
            <h2>Emails:</h2>
            <div className="accordion">
                {emails.map((email) => (
                    <div key={email.id} className="accordion-item">
                        <div
                            className={`accordion-header ${selectedEmailId === email.id ? 'active' : ''}`}
                            onClick={() => handleEmailClick(email.id)}
                        >
                            Email ID: {email.id}
                        </div>
                        {selectedEmailId === email.id && (
                            <div className="accordion-content">
                                <p><strong>Date:</strong> {selectedEmailDate}</p>
                                <p><strong>Content:</strong> {selectedEmailText}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default MuscleboosterOtpCodePage;
